@tailwind base;
@tailwind components;
@tailwind utilities;

/* tailwind config for kiosk terms */

.kiosk .text-base {
  @apply text-[1.5vh] leading-normal; /* Increase base text size */
}

.kiosk .text-lg {
  @apply text-[1.5vh] leading-normal; /* Increase larger text sizes */
}

.kiosk .text-xl {
  @apply text-[1.5vh] leading-normal; /* Increase larger text sizes */
}

.kiosk .text-2xl {
  @apply text-[1.5vh] leading-normal; /* Increase larger text sizes */
}

.kiosk .text-6xl {
  @apply text-[1.5vh] leading-normal; /* Increase larger text sizes */
}

/* Remove number input arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
