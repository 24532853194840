.falling-brick-container {
  background-color: #006cb7;
  position: relative;
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.falling-brick {
  width: 50px;
  height: 2.4em;
  background-color: #8b4513;
  box-sizing: border-box;
  position: relative;
}

.one {
  width: 2em;
}

.two {
  width: 4em;
}

.three {
  width: 6em;
}

.four {
  width: 7.95em;
}

.falling-brick .nodule {
  width: 1.2em;
  height: 0.45em;
  background-color: inherit;
  position: absolute;
  top: -0.45em;
  border-bottom: 0.031em solid rgb(255, 255, 255, 0.25);
  border-radius: 2px 2px 0 0;
}

.falling-brick .nodule:before {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.25) 0%,
    rgba(255, 255, 255, 0) 25%,
    rgba(255, 255, 255, 0) 50%,
    rgba(36, 36, 36, 0) 75%,
    rgba(0, 0, 0, 0.25) 100%
  );
  height: 100%;
  pointer-events: none;
  border-radius: 2px 2px 0 0;
}

.falling-brick .nodule:after {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0) 45%,
    rgba(255, 255, 255, 0.2) 47%,
    rgba(255, 255, 255, 0.2) 53%,
    rgba(255, 255, 255, 0) 55%,
    rgba(255, 255, 255, 0) 100%
  );
  height: 100%;
  pointer-events: none;
  border-radius: 2px 2px 0 0;
}

.falling-brick .nodule:nth-child(1) {
  left: 0.4em;
}

.falling-brick .nodule:nth-child(2) {
  left: 2.4em;
}

.falling-brick .nodule:nth-child(3) {
  right: 2.4em;
}

.falling-brick .nodule:nth-child(4) {
  right: 0.4em;
}
