.brick-wall-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

#text-container {
  flex: 0 0 0%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: Arial, sans-serif;
  z-index: 2;
}

#text-container h1 {
  margin: 0;
}

#text-container h2 {
  margin: 0;
  font-size: 3rem;
}

#brick-container {
  flex: 0 0 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  z-index: 1;
  font-size: 24px;
}

.row {
  display: flex;
  position: absolute;
  left: 0;
}

.brick {
  width: 7.95em;
  height: 2.4em;
  border: 0.031em solid rgba(0, 0, 0, 0.25);
  border-right-width: 0;
  background-color: #00963e;
  box-sizing: border-box;
  opacity: 0;
  position: relative;
}

.brick .nodule {
  width: 1.2em;
  height: 0.45em;
  background-color: inherit;
  position: absolute;
  top: -0.45em;
  border-bottom: 0.031em solid rgb(255, 255, 255, 0.25);
  border-radius: 2px 2px 0 0;
}

.brick .nodule:before {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(90deg,
      rgba(0, 0, 0, 0.25) 0%,
      rgba(255, 255, 255, 0) 25%,
      rgba(255, 255, 255, 0) 50%,
      rgba(36, 36, 36, 0) 75%,
      rgba(0, 0, 0, 0.25) 100%);
  height: 100%;
  pointer-events: none;
  border-radius: 2px 2px 0 0;
}

.brick .nodule:after {
  content: "";
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 45%,
      rgba(255, 255, 255, 0.2) 47%,
      rgba(255, 255, 255, 0.2) 53%,
      rgba(255, 255, 255, 0) 55%,
      rgba(255, 255, 255, 0) 100%);
  height: 100%;
  pointer-events: none;
  border-radius: 2px 2px 0 0;
}

.brick .nodule:nth-child(1) {
  left: 0.4em;
}

.brick .nodule:nth-child(2) {
  left: 2.4em;
}

.brick .nodule:nth-child(3) {
  right: 2.4em;
}

.brick .nodule:nth-child(4) {
  right: 0.4em;
}

.special1 {
  background-color: #9acea3;
}

.special2 {
  background-color: #006a2a;
}

.special3 {
  background-color: #006a2a;
}

.fade-out {
  animation: fadeOut 2s forwards;
}

@keyframes fadeOut {
  to {
    opacity: 0;
  }
}

#fade-container {
  display: none;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-color: green;
  z-index: 3;
}