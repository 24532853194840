@keyframes moveUp {
  0% {
    top: 100%;
  }
  100% {
    top: 0;
  }
}

.animate-up {
  top: 100%;
  animation: moveUp 2s ease-out forwards;
}
